import contact from '../assets/images/contact.svg';
import React from 'react'
import Navbar from '../component/navbar'
import FixFlip from '../component/fix-flip';
import Footer from '../component/footer';


export default function Flip() {
    
    return (
        <>
            <Navbar />
            <section className="py-28 w-full table relative bg-[url('../../assets/images/bg/bg-5.jpg')] bg-bottom bg-no-repeat" id="home">
                <div className="absolute inset-0 bg-slate-950/80"></div>

                <div className="container">
                    <div className="grid grid-cols-1 pb-8 text-center mt-10">
                        <h3 className="font-medium leading-normal text-3xl mt-10 text-white">Portfolio Loans</h3>
                    </div>
                </div>
            </section>
            <section className="relative lg:py-24 py-16">
                <div className="container" >
                    <section className="full-width-iframe" >
                        <iframe
                            id="JotFormIFrame-241460464555154"
                            title="Construction Loans"
                            allowtransparency="true"
                            allow="geolocation; microphone; camera; fullscreen"
                            src="https://form.jotform.com/241460464555154"
                            scrolling="yes"
                            onLoad={() => window.parent.scrollTo(0, 0)}
                            style={{
                                width: '100%', // Default width for mobile
                                height: '1200px',
                                border: 'none',
                                display: 'block',
                                margin: '0 auto',
                                '@media (min-width: 768px)': {
                                    width: '752px', // Width for desktop screens
                                },
                            }}
                        />
                        <script src='https://cdn.jotfor.ms/s/umd/latest/for-form-embed-handler.js'></script>
                        <script>
                            {`
                            window.jotformEmbedHandler("iframe[id='JotFormIFrame-241460464555154']", "https://form.jotform.com/")
                            `}
                        </script>
                    </section>
                </div>
            </section>
            <Footer />
        </>
    )
}

