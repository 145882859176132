import React from 'react';
import { Link } from "react-router-dom";
import image from '../assets/images/flip.png';
import image1 from '../assets/images/construction.png';
import image2 from '../assets/images/dscr.jpg';
import image3 from '../assets/images/Bridgeloan.png';
import image4 from '../assets/images/portfolio.png';
import image5 from '../assets/images/multfam.png';
import image6 from '../assets/images/multfam.png';
import image7 from '../assets/images/multfam.png';
import image8 from '../assets/images/bizloan.png';

export default function Detail() {
    const services = [
        {
            
            title: 'Fix & Flip',
            description: "Elevate your fix and flip projects with our specialized Fix & Flip loans. We empower investors to transform properties and maximize returns with ease.",
            link: '/flip',
            image: image
        },
        {
            
            title: 'New Construction',
            description: "Build your vision from the ground up with our New Construction loans. Tailored financing solutions support your new development projects with confidence.",
            link: '/construction',
            image: image1
        },
        {
            
            title: 'DSCR - 30 year',
            description: "Secure your long-term success with our DSCR loans. We provide property owners with stable, 30-year financing solutions tailored to support your real estate endeavors.",
            link: '/dscr',
            image: image2
        },
        {
            
            title: 'Bridge Loan',
            description: "Bridge the gap to your real estate goals with our strategic Bridge loans. Seamlessly transition between properties with short-term financing solutions designed to keep your investments on track.",
            link: '/bridge',
            image: image3
        },
        {
            
            title: 'Portfolio Loan',
            description: "Expand your real estate portfolio with confidence through our Portfolio loans. Unlock opportunities for growth and success in your investment ventures.",
            link: '/portfolio',
            image: image4
        },
        {
            
            title: 'Multi-Family Financing',
            description: "Enhance your multi-family properties with our specialized financing solutions. Discover how our Multi-family loans can elevate the value and appeal of your real estate investments.",
            link: '/multi-family-value',
            image: image6
        },
        {
            
            title: 'Business Loan',
            description: "Empower your business growth with our strategic financing solutions. Explore our Business loans section for lines of credit and revenue-based lending options tailored to fuel your success.",
            link: '/business',
            image: image8
        }
    ];


    return (
        <>
            {services.map((service, index) => (
                <div key={index} className="group relative lg:px-6 mt-4 transition duration-500 ease-in-out rounded-xl overflow-hidden text-center" style={{ backgroundImage: `url(${service.image})`, backgroundSize: 'cover', backgroundPosition: 'center', height: '300px', paddingTop: '20px' }}>
                    <div className="relative overflow-hidden text-transparent -m-3">
                        <div className="absolute top-2/4 -translate-y-2/4 start-0 end-0 mx-auto text-violet-600 rounded-xl transition duration-500 ease-in-out text-3xl flex align-middle justify-center items-center">
                            <i className={`uil uil-${service.icon}`}></i>
                        </div>
                    </div>
                    <div className="mt-6">
                        <h5 className="text-lg h5 transition duration-500 ease-in-out">{service.title}</h5>
                        <p className="text-slate-380 transition duration-500 ease-in-out mt-3" style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>
                            {service.description}
                        </p>
                        <div className="mt-8">
                            <Link to={service.link} className="py-2 px-5 inline-block font-normal tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white rounded-md">Apply Here</Link>
                        </div>
                    </div>
                </div>
            ))}
        </>
    )
}
