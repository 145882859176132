import React from 'react'
import { Link } from "react-router-dom";
import { Hexagon } from 'react-feather';

export default function ContactData() {
    const ContactData = [
        {
            icon: 'uil uil-phone',
            title: 'Phone',
            detail: 'Ready to discuss your real estate financing needs? Our team is just a call away, standing by to help you navigate your investment journey with confidence.',
            type: '615-554-5844',
            link: 'tel:+16155545844' // Telephone link
        },
        {
            icon: 'uil uil-envelope',
            title: 'Email',
            detail: 'Have a question or need assistance? Drop us an email anytime. Ready to provide swift and tailored solutions to support your real estate ventures.',
            type: 'loans@signumfinancial.co',
            link: 'mailto:loans@signumfinancial.co' // Email link
        },
        {
            icon: 'uil uil-map-marker',
            title: 'Location',
            detail: 'Local to Nashville, TN? Let\'s meet up and discuss your hard money financing needs. Success in real estate starts with a conversation.',
            type: 'Franklin, TN',
            link: '#' // Address link
        }
    ]
    return (
        <>
            <div className="container lg:mt-24 mt-16">
                <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-[30px]">
                    {ContactData.map((item, index) => (

                        <div className="text-center px-6" key={index}>
                            <div className="relative overflow-hidden text-transparent -m-3">
                                <Hexagon className="h-28 w-28 fill-violet-600/5 mx-auto rotate-[30deg]"></Hexagon>
                                <div className="absolute top-2/4 -translate-y-2/4 start-0 end-0 mx-auto text-violet-600 rounded-xl transition-all duration-500 ease-in-out text-4xl flex align-middle justify-center items-center">
                                    <i className={item.icon}></i>
                                </div>
                            </div>

                            <div className="content mt-7">
                                <h5 className="title h5 text-xl font-medium">{item.title}</h5>
                                <p className="text-slate-400 mt-3">{item.detail}</p>

                                <div className="mt-5">
                                    <Link to={item.link} className="text-violet-600 hover:text-violet-600 after:bg-violet-600 transition duration-500">{item.type}</Link>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}
