import logo_icon_64 from '../assets/images/logo-icon-64.png';
import React from 'react'
import { Link } from "react-router-dom";

export default function Footer() {

    return (
        <>
            <footer className="footer bg-slate-950 relative text-gray-200 dark:text-gray-200">
                <div className="container">
                    <div className="grid grid-cols-12">
                        <div className="col-span-12">
                            <div className="py-[60px] px-0">
                                <div className="grid grid-cols-1">
                                    <div className="text-center">
                                        <img src={logo_icon_64} className="block mx-auto" alt="" />
                                        <p className="max-w-xl mx-auto text-slate-400 mt-8">Unlocking opportunities, one property at a time. Your trusted partner in Real Estate Hard Money Financing.
                                        </p>
                                    </div>

                                    <ul className="list-none footer-list text-center mt-8">
                                        <li className="inline px-2"><Link to="/services"
                                            className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out">Loan Programs</Link>
                                        </li>
                                        <li className="inline px-2 mt-[10px]"><Link to="/aboutus"
                                            className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out ms-2">About
                                            Us</Link></li>
                                        {/* --hide for now--
<li className="inline px-2 mt-[10px]"><Link to="/blogs"
                                            className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out ms-2">Articles</Link>
                                        </li>*/} 
                                        {/*Hiding this section temporary <li className="inline px-2 mt-[10px]"><Link to="/team"
                                            className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out ms-2">Team</Link>
    </li>*/}
                                        <li className="inline px-2 mt-[10px]"><Link to="/contactus"
                                            className="text-gray-300 hover:text-gray-400 duration-500 ease-in-out ms-2">Contact
                                            Us</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="py-[30px] px-0 border-t border-slate-800">
                    <div className="container text-center">
                        <div className="grid md:grid-cols-12 items-center">
                            <div className="md:col-span-6">
                                <div className="md:text-start text-center">
                                    <p className="text-gray-400">©
                                        {new Date().getFullYear()} Signum Financial, LLC
                                    </p>
                                </div>
                            </div>

                            <div className="md:col-span-6 md:mt-0 mt-8">
                                <ul className="list-none md:text-end text-center">
                                    <li className="inline ms-1"><Link to="https://www.linkedin.com/company/signum-financial/" target="_blank"
                                        className="h-8 w-8 inline-flex items-center text-center justify-center text-base font-normal tracking-wide align-middle transition duration-500 ease-in-out border border-gray-800 rounded-md hover:border-violet-600 dark:hover:border-violet-600 hover:bg-violet-600 dark:hover:bg-violet-600"><i
                                            className="uil uil-linkedin" title="Linkedin"></i></Link></li>
                                    <li className="inline ms-1"><Link to="https://www.facebook.com/SignumFinancial.llc" target="_blank"
                                        className="h-8 w-8 inline-flex items-center text-center justify-center text-base font-normal tracking-wide align-middle transition duration-500 ease-in-out border border-gray-800 rounded-md hover:border-violet-600 dark:hover:border-violet-600 hover:bg-violet-600 dark:hover:bg-violet-600"><i
                                            className="uil uil-facebook-f align-middle" title="facebook"></i></Link></li>
                                    <li className="inline ms-1"><Link to="https://www.instagram.com/signumfinancial/" target="_blank"
                                        className="h-8 w-8 inline-flex items-center text-center justify-center text-base font-normal tracking-wide align-middle transition duration-500 ease-in-out border border-gray-800 rounded-md hover:border-violet-600 dark:hover:border-violet-600 hover:bg-violet-600 dark:hover:bg-violet-600"><i
                                            className="uil uil-instagram align-middle" title="instagram"></i></Link></li>
                                    <li className="inline ms-1"><Link to="mailto:loans@signumfinancial.co"
                                        className="h-8 w-8 inline-flex items-center text-center justify-center text-base font-normal tracking-wide align-middle transition duration-500 ease-in-out border border-gray-800 rounded-md hover:border-violet-600 dark:hover:border-violet-600 hover:bg-violet-600 dark:hover:bg-violet-600"><i
                                            className="uil uil-envelope align-middle" title="email"></i></Link></li>                                   
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}
