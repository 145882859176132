import React from 'react';
import { Link } from "react-router-dom";
import { Hexagon } from 'react-feather';

export default function OurServices() {
    const services = [
        {
            
            title: 'Fix & Flip',
            description: "Elevate your fix and flip projects with our specialized Fix & Flip loans. We empower investors to transform properties and maximize returns with ease.",
        },
        {
            
            title: 'New Construction',
            description: "Build your vision from the ground up with our New Construction loans. Tailored financing solutions support your new development projects with confidence.",
        },
        {
            
            title: 'DSCR - 30 year',
            description: "Secure your long-term success with our DSCR loans. We provide property owners with stable, 30-year financing solutions tailored to support your real estate endeavors.",
        },
        {
            
            title: 'Bridge Loan',
            description: "Bridge the gap to your real estate goals with our strategic Bridge loans. Seamlessly transition between properties with short-term financing solutions designed to keep your investments on track.",
        },
        {
            
            title: 'Portfolio Loan',
            description: "Expand your real estate portfolio with confidence through our Portfolio loans. Unlock opportunities for growth and success in your investment ventures.",
        },
        {
            
            title: 'Multi-Family Stabilized',
            description: "Explore our Multi-family loans section for tailored financing solutions that maximize the potential of your investment properties.",
        },
        {
            
            title: 'Multi-Family Value Add',
            description: "Enhance your multi-family properties with our specialized financing solutions. Discover how our Multi-family loans can elevate the value and appeal of your real estate investments.",
        },
        {
            
            title: 'Multi-Family Development',
            description: "Embark on your multi-family construction projects with confidence. Our Multi-family development loans are designed to support your vision and bring your developments to life.",
        },
        {
            
            title: 'Business Loan',
            description: "Empower your business growth with our strategic financing solutions. Explore our Business loans section for lines of credit and revenue-based lending options tailored to fuel your success.",
        }
    ];
    return (
        <section className="relative md:py-24 py-16 bg-gray-50 dark:bg-slate-800" id="features">
            <div className="container lg mx-auto">
                <div className="grid grid-cols-1 pb-8 text-center">
                    <h3 className="mb-4 md:text-2xl text-xl font-medium">Loan Programs</h3>
                    <p className="text-slate-400 max-w-xl mx-auto">
                    Discover tailored solutions for your real estate projects with our diverse Loan Programs. Explore options designed to meet your financing needs and propel your investments forward.
                    </p>
                </div>

                <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-4 gap-[30px]">
                    {services.map((service, index) => (
                        <div key={index} className="group relative lg:px-6 mt-4 transition duration-500 ease-in-out rounded-xl overflow-hidden text-center">
                            <div className="relative overflow-hidden text-transparent -m-3">
                                
                                <div className="absolute top-2/4 -translate-y-2/4 start-0 end-0 mx-auto text-violet-600 rounded-xl transition duration-500 ease-in-out text-3xl flex align-middle justify-center items-center">
                                    <i className={`uil uil-${service.icon}`}></i>
                                </div>
                            </div>
                            <div className="mt-6">
                                <Link to="/services" className="text-lg h5 transition duration-500 ease-in-out hover:text-violet-600" >
                                    {service.title}
                                </Link>
                                <p className="text-slate-400 transition duration-500 ease-in-out mt-3">
                                    {service.description}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
};