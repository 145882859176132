import contact from '../assets/images/contact.svg';
import React from 'react'
import Navbar from '../component/navbar'
import ContactData from '../component/contact-data';
import Footer from '../component/footer';
import backgroundImage from '../assets/images/portfolio.png';

export default function ContactUs() {
    return (
        <>
            <Navbar />
            <section className="py-28 w-full table relative bg-[url('../../assets/images/bg/bg-5.jpg')] bg-bottom bg-no-repeat" id="home">
                <div className="absolute inset-0 bg-slate-950/80"></div>

                <div className="container">
                    <div className="grid grid-cols-1 pb-8 text-center mt-10">
                        <h3 className="font-medium leading-normal text-3xl mt-10 text-white">Contact Us</h3>
                    </div>
                </div>
            </section>
            <section className="relative lg:py-24 py-16">
                <div className="container">
                    <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]" >
                        <div className="lg:col-span-6" >
                            <div className="bg-white dark:bg-slate-900 rounded-md shadow dark:shadow-gray-700 p-6 h-full" >

                                <section className="full-width-iframe" >
                        <iframe
                            id="JotFormIFrame-241478299429068"
                            title="Construction Loans"
                            allowtransparency="true"
                            allow="geolocation; microphone; camera; fullscreen"
                            src="https://form.jotform.com/241478299429068"
                            scrolling="no"
                            onLoad={() => window.parent.scrollTo(0, 0)}
                            style={{ width: '100%', height: '550px', border: 'none', padding: '0', margin: '0' }}
                        />
                        <script src='https://cdn.jotfor.ms/s/umd/latest/for-form-embed-handler.js'></script>
                        <script>
                            {`
                            window.jotformEmbedHandler("iframe[id='JotFormIFrame-241478299429068']", "https://form.jotform.com/")
                            `}
                        </script>
                    </section>
                            </div>
                        </div>

                        <div className="lg:col-span-6 relative" style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', height: '100%', display: 'flex', alignItems: 'center' }}>
                            <div className="rounded-md p-6 h-full" style={{  width: '90%', margin: 'auto' }}>
                                <p className="text-lg text-gray-800 dark:text-white font-medium leading-normal">
                                    Are you seeking exclusive off-market deals that are off the radar for others? You've landed in the perfect spot. Dive into our newsletter to unearth these hidden gems. Take the first step by completing the form below to enroll as a member and kickstart your journey to receiving exclusive off-market deals.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <ContactData />
            </section>
            {/* --hide for now--<div className="container-fluid relative">
                <div className="grid grid-cols-1">
                    <div className="w-full leading-[0] border-0">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d103368.37939428118!2d-86.90499369180408!3d35.94056374237281!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88647eb530697f93%3A0x6a17a8b9161c4c33!2s3401%20Mallory%20Ln%2C%20Franklin%2C%20TN%2037067!5e0!3m2!1sen!2sus!4v1716673697497!5m2!1sen!2sus"
                            style={{ border: 0 }} className="w-full h-[500px]" title="contactUs" allowfullscreen> </iframe>
                    </div>
                </div>
            </div>*/} 
            <Footer />
        </>
    )
}