import './App.css';
import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { SpeedInsights } from "@vercel/speed-insights/react"
import { Analytics } from "@vercel/analytics/react"
import AboutUs from './main/aboutus';
import './assets/css/tailwind.css'
import './assets/libs/@mdi/font/css/materialdesignicons.min.css'
import './assets/libs/@iconscout/unicons/css/line.css'
import './assets/libs/tobii/css/tobii.min.css'
import './assets/images/favicon.ico'
import BlogDetail from './main/blog-detail';
import Blogs from './main/blogs';
import ContactUs from './main/contactus';
import Index from './main';
import Login from './main/login';
import ResetPassword from './main/reset-password';
import Services from './main/services';
import Signup from './main/signup';
import Team from './main/team';
import FixFlip from './main/flip';
import Construction from './main/construction';
import Dscr from './main/dscr';
import Bridge from './main/bridge';
import Portfolio from './main/portfolio';
import MultiFamilyStable from './main/multi-family-stable';
import MultiFamilyValue from './main/multi-family-value';
import MultiFamilyDevelop from './main/multi-family-develop';
import Business from './main/business';
import Switcher from './component/Switcher';
import ScrollToTop from './component/Scroll-top';



export default function App() {


  useEffect(() => {
    {/* --hide for now--
document.documentElement.setAttribute("dir", "ltr");*/} 
    document.documentElement.classList.add('light');
  }, []);

  return (

    <BrowserRouter >
        <div>
      {/*Speed insights */}
        <SpeedInsights />
        <Analytics />
    </div>
      <ScrollToTop />
      <Routes>
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/blog-detail" element={<BlogDetail />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/contactus" element={<ContactUs />} />
        <Route path="/index" element={<Index />} />
        <Route path="/login" element={<Login />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/services" element={<Services />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/team" element={<Team />} />
        <Route path="/" element={<Index />} />
        <Route path="/flip" element={<FixFlip />} />
        <Route path="/construction" element={<Construction />} />
        <Route path="/dscr" element={<Dscr />} />
        <Route path="/bridge" element={<Bridge />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/multi-family-stable" element={<MultiFamilyStable />} />
        <Route path="/multi-family-value" element={<MultiFamilyValue />} />
        <Route path="/multi-family-develop" element={<MultiFamilyDevelop />} />
        <Route path="/business" element={<Business />} />

      </Routes>
      <Switcher />
    </BrowserRouter>
  )
};