import image from '../assets/images/blog/9.jpg';
import image1 from '../assets/images/client/05.jpg';
import React from 'react'
import Navbar from '../component/navbar'
import RelatedBlogs from '../component/related-blogs';
import Footer from '../component/footer';
import { Link } from "react-router-dom";
import { Facebook, Instagram, Linkedin, Mail, MessageCircle, User, Youtube } from 'react-feather';

export default function BlogDetail() {
    return (
        <>
            <Navbar />
            <section className="py-28 w-full table relative bg-[url('../../assets/images/bg/bg-5.jpg')] bg-bottom bg-no-repeat" id="home">
                <div className="absolute inset-0 bg-slate-950/80"></div>

                <div className="container">
                    <div className="grid grid-cols-1 pb-8 text-center mt-10">
                        <h3 className="mb-3 font-medium leading-normal text-3xl mt-10 text-white">Maximizing Your Profits: The Ultimate Guide to Fix and Flip Loans</h3>

                        <ul className="list-none mt-6">
                            {/* --hide for now--
<li className="inline-block text-white/50 mx-5"> <span className="text-white block">Client :</span> <span className="block">Calvin Carlo</span></li>*/} 
                            <li className="inline-block text-white/50 mx-5"> <span className="text-white block">Date :</span> <span className="block"> May 25th, 2024</span></li>
                            {/* --hide for now--<li className="inline-block text-white/50 mx-5"> <span className="text-white block">Time :</span> <span className="block">8 Min Read</span></li>*/} 
                        </ul>
                    </div>
                </div>
            </section>
            <section className="relative md:py-24 py-16">
                <div className="container">
                    <div className="grid md:grid-cols-12 grid-cols-1 gap-[30px]">
                        <div className="lg:col-span-8 md:col-span-6">
                            <div className="p-6 rounded-md shadow dark:shadow-gray-800">

                                <img src={image} className="rounded-md" alt="" />

                                <div className="mt-6">
                                    <p className="text-slate-400">Fix and flip loans are a type of financing that is specifically designed for real estate investors who buy properties with the intention of renovating them and selling them for a profit. These loans are short-term in nature and are typically used to cover the purchase price of the property as well as the costs of renovations.</p>
                                    <p className="text-slate-400">Fix and flip loans have become increasingly popular among real estate investors for several reasons. First, they provide investors with the necessary capital to purchase properties that they may not have been able to afford otherwise. This allows them to take advantage of investment opportunities and potentially earn a higher return on their investment. Additionally, fix and flip loans offer flexibility in terms of repayment options, allowing investors to repay the loan once the property is sold.</p>
                                    <p className="text-slate-400 italic border-x-4 border-violet-600 rounded-ss-xl rounded-ee-xl mt-3 p-3">" There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. "</p>
                                    <p className="text-slate-400 mt-3">The advantage of its Latin origin and the relative meaninglessness of Lorum Ipsum is that the text does not attract attention to itself or distract the viewer's attention from the layout.</p>
                                </div>
                            </div>

                            {/* --hide for now--<div className="p-6 rounded-md shadow dark:shadow-gray-800 mt-8">
                                <h5 className="text-lg font-semibold">Leave A Comment:</h5>

                                <form className="mt-8">
                                    <div className="grid lg:grid-cols-12 lg:gap-6">
                                        <div className="lg:col-span-6 mb-5">
                                            <div className="text-start">
                                                <label htmlFor="name" className="font-semibold">Your Name:</label>
                                                <div className="form-icon relative mt-2">
                                                    <User className="w-4 h-4 absolute top-3 start-4"></User>
                                                    <input name="name" id="name" type="text" className="form-input w-full py-2 px-3 ps-11 bg-transparent border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded outline-none focus:border-violet-600/50 dark:focus:border-violet-600/50 focus:ring-0" placeholder="Name :" />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="lg:col-span-6 mb-5">
                                            <div className="text-start">
                                                <label htmlFor="email" className="font-semibold">Your Email:</label>
                                                <div className="form-icon relative mt-2">
                                                    <Mail className="w-4 h-4 absolute top-3 start-4"></Mail>
                                                    <input name="email" id="email" type="email" className="form-input w-full py-2 px-3 ps-11 bg-transparent border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded outline-none focus:border-violet-600/50 dark:focus:border-violet-600/50 focus:ring-0" placeholder="Email :" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="grid grid-cols-1">
                                        <div className="mb-5">
                                            <div className="text-start">
                                                <label htmlFor="comments" className="font-semibold">Your Comment:</label>
                                                <div className="form-icon relative mt-2">
                                                    <MessageCircle className="w-4 h-4 absolute top-3 start-4"></MessageCircle>
                                                    <textarea name="comments" id="comments" className="form-input w-full py-2 px-3 ps-11 h-28 bg-transparent border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded outline-none focus:border-violet-600/50 dark:focus:border-violet-600/50 focus:ring-0" placeholder="Message :"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <button type="submit" id="submit" name="send" className="py-2 px-5 inline-block font-normal tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white rounded-md w-full">Send Message</button>
                                </form>
                            </div>*/} 
                        </div>

                        <div className="lg:col-span-4 md:col-span-6">
                            <div className="sticky top-20">
                                <h5 className="text-lg font-medium bg-gray-50 dark:bg-slate-800 shadow dark:shadow-gray-800 rounded-md p-2 text-center">Author</h5>
                                <div className="text-center mt-8">
                                    <img src={image1} className="h-24 w-24 mx-auto rounded-full shadow mb-4" alt="" />

                                    <Link to="" className="text-lg font-medium hover:text-violet-600 transition-all duration-500 ease-in-out h5">Brian Jenkins</Link>
                                    <p className="text-slate-400">Content Writer</p>
                                </div>

                                <h5 className="text-lg font-medium bg-gray-50 dark:bg-slate-800 shadow dark:shadow-gray-800 rounded-md p-2 text-center mt-8">Social sites</h5>
                                <ul className="list-none text-center mt-8">
                                    <li className="inline"><Link to="https://www.facebook.com/bwjenkinsvii/" className="h-8 w-8 inline-flex items-center text-center justify-center text-base font-normal tracking-wide align-middle transition duration-500 ease-in-out border border-gray-100 dark:border-gray-800 rounded-md text-slate-400 hover:border-violet-600 hover:text-white hover:bg-violet-600"><Facebook className="h-4 w-4"></Facebook></Link></li>
                                    <li className="inline ms-1"><Link to="https://www.instagram.com/bwjenkinsvii/" className="h-8 w-8 inline-flex items-center text-center justify-center text-base font-normal tracking-wide align-middle transition duration-500 ease-in-out border border-gray-100 dark:border-gray-800 rounded-md text-slate-400 hover:border-violet-600 hover:text-white hover:bg-violet-600"><Instagram  className="h-4 w-4"></Instagram></Link></li>
                                    <li className="inline ms-1"><Link to="https://www.linkedin.com/in/nashhardmoney/" className="h-8 w-8 inline-flex items-center text-center justify-center text-base font-normal tracking-wide align-middle transition duration-500 ease-in-out border border-gray-100 dark:border-gray-800 rounded-md text-slate-400 hover:border-violet-600 hover:text-white hover:bg-violet-600"><Linkedin className="h-4 w-4"></Linkedin></Link></li>
                                    <li className="inline ms-1"><Link to="https://www.youtube.com/channel/UCt8JzOn1nEO2YYQNO0TTNbg" className="h-8 w-8 inline-flex items-center text-center justify-center text-base font-normal tracking-wide align-middle transition duration-500 ease-in-out border border-gray-100 dark:border-gray-800 rounded-md text-slate-400 hover:border-violet-600 hover:text-white hover:bg-violet-600"><Youtube className="h-4 w-4"></Youtube></Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <RelatedBlogs />
                <div className="container lg:mt-24 mt-16">
                    <div className="grid grid-cols-1 text-center">
                        <h3 className="mb-6 md:text-2xl text-xl font-medium">Have Question ? Get in touch!</h3>

                        <p className="text-slate-400 max-w-xl mx-auto">This is just a simple text made for this unique and awesome template, you can replace it with any text.</p>

                        <div className="mt-6">
                            <Link to="/contactus" className="py-2 px-5 inline-block font-normal tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white rounded-md"><i className="uil uil-phone align-middle me-2"></i> Contact us</Link>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}
