import React from 'react'
import { Link } from "react-router-dom";

export default function GetInTouch() {
    return (
        <>
            <section className="relative md:py-24 py-16 bg-gray-50 dark:bg-slate-800" id="contact">
                <div className="container">
                    <div className="grid grid-cols-1 pb-8 text-center">
                        <h3 className="mb-4 md:text-2xl text-xl font-medium">Get In Touch !</h3>
                        <p className="text-slate-400 dark:text-slate-300 max-w-xl mx-auto">Take the first step toward your real estate goals. Reach out today to explore tailored hard money financing solutions for your investments. If you're looking for off-market deals, drop us some details on your buybox as well and we'll keep an eye out for you!</p>
                    </div>
                    <div className="grid grid-cols-1 lg:grid-cols-12 md:grid-cols-2 mt-8 items-center gap-[30px]">
                        <div className="lg:col-span-8">
                        <div className="bg-white dark:bg-slate-900 rounded-md shadow dark:shadow-gray-700 p-6 h-full">
                                

                                <section className="full-width-iframe">
                        <iframe
                            id="JotFormIFrame-241478299429068"
                            title="Construction Loans"
                            allowtransparency="true"
                            allow="geolocation; microphone; camera; fullscreen"
                            src="https://form.jotform.com/241478299429068"
                            scrolling="no"
                            onLoad={() => window.parent.scrollTo(0, 0)}
                            style={{ width: '100%', height: '100%', height: '500px', border: 'none', padding: '0' }}
                        />
                        <script src='https://cdn.jotfor.ms/s/umd/latest/for-form-embed-handler.js'></script>
                        <script>
                            {`
                            window.jotformEmbedHandler("iframe[id='JotFormIFrame-241478299429068']", "https://form.jotform.com/")
                            `}
                        </script>
                    </section>
                            </div>
                        </div>

                        <div className="lg:col-span-4">
                            <div className="lg:ms-8">
                                <div className="flex">
                                    <div className="icons text-center mx-auto">
                                        <i className="uil uil-phone block rounded text-2xl dark:text-white mb-0"></i>
                                    </div>

                                    <div className="flex-1 ms-6">
                                        <h5 className="text-lg dark:text-white mb-2 font-medium">Phone</h5>
                                        <Link to="tel:+1615-554-5844" className="text-slate-400">615-554-5844</Link>
                                    </div>
                                </div>

                                <div className="flex mt-4">
                                    <div className="icons text-center mx-auto">
                                        <i className="uil uil-envelope block rounded text-2xl dark:text-white mb-0"></i>
                                    </div>

                                    <div className="flex-1 ms-6">
                                        <h5 className="text-lg dark:text-white mb-2 font-medium">Email</h5>
                                        <Link to="mailto:brian@signumfinancial.com" className="text-slate-400">loans@signumfinancial.co</Link>
                                    </div>
                                </div>

                                <div className="flex mt-4">
                                    <div className="icons text-center mx-auto">
                                        <i className="uil uil-map-marker block rounded text-2xl dark:text-white mb-0"></i>
                                    </div>

                                    <div className="flex-1 ms-6">
                                        <h5 className="text-lg dark:text-white mb-2 font-medium">Location</h5>
                                        <p className="text-slate-400 mb-2">Franklin, TN</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
